/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'playhera-logo': {
    width: 1009,
    height: 141,
    viewBox: '0 0 1009 141',
    data: '<path pid="0" d="M103.78 46.81c0 26-20.75 47.2-46.26 47.2H19.06V84h38.46c20 0 36.26-16.69 36.26-37.2S77.51 9.6 57.52 9.6H10v131H0V0h63.47c22.71 3 40.31 22.84 40.31 46.81zM133.92 130.91h93.47v10H124.23v-.32h-.31V0h10v130.91zM315.18 0l55.47 141h-10.51L310.29 17.43 259.48 141h-10.54L305.52 0h9.66zM466.57 0l-46.92 74.76v65.83h-10V76.63L361.8 0h11.78l40.49 64.83L454.77 0h11.8zM500.66 0h-10v140.59h10V0zM590.45 0v65.26h-78.72v10h78.72v65.33h10V0h-10zM628.59 0v140.59h.31v.32h103.16v-10h-93.47V75.26h93.47v-10h-93.47V9.61h93.47V0H628.59zM788.99 94l75.44 47h-18.91l-75.15-46.8.11-.19h-.28V84h52.52c20 0 36.26-16.69 36.26-37.2S842.71 9.6 822.72 9.6h-61.85V0h67.85c22.71 3 40.31 22.84 40.31 46.81 0 26-20.75 47.2-46.26 47.2L788.99 94zM1008.24 141h-10.51L947.88 17.43 897.07 141h-10.54L943.1 0h9.67l55.47 141z" _fill="#fff"/>'
  }
})
